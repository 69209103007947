import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['accepted', 'default', 'warning']
  static targets = ['display', 'input']
  static values = {
    max: Number,
    min: Number
  }

  connect () {
    this.updateCounter()
  }

  updateCounter () {
    const length = this.inputTarget.value.length
    const maxLength = this.maxValue || 160
    const minLength = this.minValue || 0

    this.displayTarget.classList.add(this.defaultClass)
    this.displayTarget.classList.remove(this.acceptedClass, this.warningClass)

    if (length < minLength) {
      this.displayTarget.classList.remove(this.acceptedClass, this.warningClass)
    } else if (length > maxLength) {
      this.displayTarget.classList.replace(this.acceptedClass, this.warningClass)
      this.displayTarget.classList.replace(this.defaultClass, this.warningClass)
    } else {
      this.displayTarget.classList.replace(this.defaultClass, this.acceptedClass)
      this.displayTarget.classList.replace(this.warningClass, this.acceptedClass)
    }

    this.displayTarget.textContent = `${length}/${maxLength}`
  }
}
